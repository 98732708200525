import React from 'react';
import styled from 'styled-components';
import Pricing from './Pricing';
import { Typography } from '@material-ui/core';

const Styles = styled.div`
.home {
    background-color: #00000;
    color: #1a1373;
    padding-left: 3%;
    padding-right: 3%;
}

.homeText {
    font-weight: 500;
}

.whatIsText{
    background-color: #00000;
    color: #1a1373;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 2%;
}

.whoAreWe{
    background-color: #00000;
    color: #1a1373;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 2%;
}

.wrapperText{
    margin-top:42px;
    margin-bottom: 42px;
}
}

`;


export const Home = () =>(
    <Styles>
    <div className="home" >
        <Typography variant="h3" className="homeText">
        Welcome!
        </Typography>
        <p>Multibase Systems Limited are Britain's leading database supplier to the asbestos industry. We have supplied industry databases 
            for 20 years and we have a wealth of experience. We deliver top quality design, ease of use and the ability to produce high specification reports. 
            All of our systems are supplied with a full remote connection technical support programme, which enables us to assist with any queries immediately.
            As a member of ARCA we keep up to date 
            with the latest changes so that you can be confident our systems comply with all regulations.</p>
    </div>
    <div className="wrapperText">
<div className="whatIsText">
    <Typography variant="h4">
        What is Multibase?
    </Typography>
    </div>
    <div className="whoAreWe">
    <Typography>
        Multibase is a system where all of your asbestos content comes together. Where you can store your data securely, keep your records up to date and where we can provide compliant systems with all the HSE guidelines.
        Below are our base prices for the systems. All of which come with full technical support! Our prices include a yearly fee of £700 for renewal costs. 
    </Typography>
    </div>
    <Pricing/>
    </div>
    </Styles>
)