import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import styled from 'styled-components';

const Styles = styled.div`
.navbar {
    background-color: white;
    border-bottom: 5px solid #191b58;
}

.navButton{
    position: relative,
    right: 0,
}
.navButton:hover {
    background-color: #465cd2 !important;
    color: #FFF;
 }

.navbar-brand, .navbar-nav .nav-link {
    color: #191b58;

    &:hover {
        color: orange;
    }
}
`;

export const NavigationBar = () => (
    <Styles>
        <Navbar expand="lg">
            <Navbar.Brand >
            <img src="http://www.multibase.co.uk/images/wwb_img15.jpg" alt="Smiley face" height="42" width="85"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic"></Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    <Nav.Item>
                        <Nav.Link href="/">Home</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/systems">Systems</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/systems">Buy Points</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="https://go.mikogo.com/?aspxerrorpath=/en/start.aspx" >Remote Connection</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/contact">Contact</Nav.Link>
                    </Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    </Styles>
)