import React from 'react';
import { Jumbotron as Jumbo, Container } from 'react-bootstrap';
import styled from 'styled-components';
import image from '../assets/imasssge.jpg';

const Styles = styled.div`
.jumbo{
    background: url(${image}) no-repeat fixed bottom;
    background-size: cover;
    color: white;
    height: 350px;
    position: relative;

}

.overlay{
    background-color: black;
    opacity: 0.6;
    position: absolute; 
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}`;

export const Jumbotron = () =>(
    <Styles>
        <Jumbo fluid className="jumbo">
            <div className="overlay">
                <Container>
                    {/* Need to change the colour of the text to see it on screen otherwise redundant as hitting the white background */}
                    <h1>Welcome</h1> 
                    <p>To multibase systems</p>
                </Container>
            </div>
        </Jumbo>
    </Styles>

)